<template>
    <div id="pageList" class="CysList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">承运商名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入承运商名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="pageList.queryParam.type" placeholder="请选择类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">合作日期：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.date" placeholder="请选择合作日期"/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">联系人：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.contacts = validForbid(e)" placeholder="请输入联系人进行模糊匹配查询..." v-model="pageList.queryParam.contacts" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">联系电话：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.telephone = validForbid(e)" placeholder="请输入联系电话进行模糊匹配查询..." v-model="pageList.queryParam.telephone" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">联系地址：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.address = validForbid(e)" placeholder="请输入联系地址进行模糊匹配查询..." v-model="pageList.queryParam.address" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">审核状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择审核状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择是否停用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">登录账号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入登录账号进行模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="承运商名称"/>
                <el-table-column prop="F_TYPE" label="类型"/>
                <el-table-column prop="F_JOIN_TYPE" label="类别"/>
                <el-table-column prop="F_DATE" label="合作日期"/>
                <el-table-column prop="F_CONTACTS" label="联系人"/>
                <el-table-column prop="F_TELEPHONE" label="联系电话"/>
                <el-table-column prop="F_ADDRESS" label="联系地址"/>
                <el-table-column prop="F_STATUS" label="审核状态"/>
                <el-table-column prop="F_FLAG" label="是否停用"/>
                <el-table-column prop="F_CODE" label="登录账号"/>
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import CysCard from './CysCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "CysList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                pageList: {
                    queryParam: {
                       name:"", 
                       type:"", 
                       status:"", 
                       flag:"", 
                       contacts:"", 
                       telephone:"", 
                       address:"", 
                       date:"", 
                       code:"", 
                       delStatus:"" 
                    },
                    modelComp: CysCard,
                    modelMethod: "/cys/pageData"
               }
            })
            onMounted(()=>{
            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'type'==params.comboId){
                        return [{value:0,label:'个人'},{value:1,label:'机构'},{value:2,label:'公司'}]
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:0,label:'待审核'},{value:1,label:'通过'},{value:2,label:'拒绝'}]
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                    }
                }
            })

            return{
                ...toRefs(dataObj),comboSelect
            }
        }
    });
</script>

<style scoped>
    .CysList{
        width: 100%;
    }
</style>